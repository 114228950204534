export const typeDefs = `

scalar AWSDateTime

input AWSDateTimeFilterInput {
  _eq: AWSDateTime
  _exists: Boolean
  _gt: AWSDateTime
  _gte: AWSDateTime
  _in: [AWSDateTime!]
  _lt: AWSDateTime
  _lte: AWSDateTime
  _neq: AWSDateTime
  _nin: [AWSDateTime!]
}

scalar AWSJSON

type AgentPostPassReport {
  agentId: String!
  markdownText: String!
  s3Key: String!
  taskDocumentId: String!
}

type AgentReport {
  agentId: String!
  taskReports: [TaskReport!]!
}

enum AngleUnits {
  DEGREE_ANGLE
  RADIAN
}

input BooleanFilterInput {
  _eq: Boolean
  _exists: Boolean
  _neq: Boolean
}

type BusinessInsightsContact {
  antennaName: String
  contactId: String!
  contactStatus: ContactStatus!
  customerId: String!
  dataDeliveryRegions: [String!]
  dataflowList: [Dataflow!]
  endTime: AWSDateTime!
  groundStationAirportCode: String
  groundStationName: String
  maximumElevation: Elevation
  missionProfileArn: String
  postPassEndTime: AWSDateTime
  prePassStartTime: AWSDateTime
  reservationArns: [String!]
  satelliteArn: String
  schedulingRegion: String
  startTime: AWSDateTime!
}

enum ConfigCapabilityType {
  ANTENNA_DOWNLINK
  ANTENNA_DOWNLINK_DEMOD_DECODE
  ANTENNA_UPLINK
  CONNECTION_PROXY
  DATAFLOW_ENDPOINT
  MANAGED_ANTENNA_DOWNLINK_DEMOD_DECODE
  S3_PLAYBACK
  S3_RECORDING
  TRACKING
  UPLINK_ECHO
}

type Contact {
  agentPostPassReports: [AgentPostPassReport!]
  id: String!
  images: [PostPassImage!]
}

input ContactByIdInput {
  id: String!
}

type ContactGlobalDDBTableV2 {
  awsAccountId: String!
  cancellationReason: String
  cancellationTimestamp: AWSDateTime
  capabilitiesToTest: [String!]
  configIdToDagNodeMap: AWSJSON
  contactEndTimeEpochSeconds: AWSDateTime!
  contactId: String!
  contactStartTimeEpochSeconds: AWSDateTime!
  contactStatus: ContactStatus!
  contactType: ContactType!
  createReservationForRegionSignatureMaps: String
  createTimestamp: AWSDateTime!
  createdInRegion: String!
  failedToScheduleReasons: [String!]
  groundStationId: String!
  maxElevationDegrees: Float!
  meteringArchiveId: String
  meteringStatus: MeteringStatus
  meteringUsage: MeteringUsage
  migrationVersion: Int
  missionProfileArn: String!
  obsoleteReservationArnList: [String!]
  payerToken: String
  postPassEndTimeEpochSeconds: AWSDateTime!
  prePassStartTimeEpochSeconds: AWSDateTime!
  reschedulingTimestamp: AWSDateTime
  reservationArnList: [String!]
  reserveContactStepFunctionId: String!
  satelliteArn: String!
  signatureMap: String
  targetRegion: String!
  updateTimestamp: AWSDateTime
  versionNumber: Int!
  versionTimestamp: AWSDateTime
}

enum ContactStatus {
  AVAILABLE
  AWS_CANCELLED
  AWS_FAILED
  CANCELLED
  CANCELLING
  COMPLETED
  FAILED
  FAILED_TO_SCHEDULE
  PASS
  POSTPASS
  PREPASS
  SCHEDULED
  SCHEDULING
}

type ContactTable {
  businessInsightsContact: BusinessInsightsContact
  contactGlobalDDBTableV2Dto: ContactGlobalDDBTableV2
  datalakeTtl: AWSDateTime!
  inRegionContactDdbTable: InRegionContactDdbTable
  pk: String!
  sk: String!
  version: Int
}

enum ContactType {
  ANTENNA_SPECIFIC
  GROUND_STATION_SPECIFIC
}

type CpContactEvent010 {
  contactId: String!
  contactStatus: String!
  customerNamePart: String!
}

enum CpCriticality {
  PREFERRED
  REMOVED
  REQUIRED
}

enum CpDataflowType {
  DDS
  DDS_V2
}

type DagNode {
  configId: String!
  dagNodeSemanticVersion: String
  dataflowEndpointDetails: String
  dataflowSourceConfigId: String
  dataflowUuid: String
  executionContextId: String
  matchedCapabilityArns: [String!]
  nodeConfigCapabilityType: ConfigCapabilityType
  outputTypeToNextConfigIdsMap: AWSJSON
  outputTypeToPreviousConfigIdsMap: AWSJSON
  region: String
  reservationId: String
  reservationStatus: ReservationStatus
  reservedCapabilityArn: String
  s3RecordingDetails: String
  sameReservationGroupAsConfigIds: [String!]
  vita49StreamId: Int
}

type Dataflow {
  dataflowId: String
  dataflowMetrics: DataflowMetrics
  destinationNode: DataflowNode
  rfCharacteristics: RfCharacteristics
  sourceNode: DataflowNode
  tags: [String!]
  vita49StreamCharacteristics: Vita49StreamCharacteristics
}

type DataflowMetrics {
  bytesReceived: Long
  bytesTransferred: Long
}

type DataflowNode {
  nodeArn: String
  nodeRegion: String
  nodeType: ConfigCapabilityType
}

type DataflowTranslation {
  egress: TranslationDetail
  ingress: TranslationDetail
}

enum DeliveryMechanism {
  DDX
  LAMINAR_FLOW
}

type DereferencedMissionProfileData {
  createdInRegion: String
  customerAwsAccountId: String
  minimumViableContactSeconds: Int
  missionProfileArn: String
  missionProfileId: String
  name: String
  postPassDurationSeconds: Int
  prePassDurationSeconds: Int
}

input EchoInput {
  input: String!
}

type EchoOutput {
  output: String!
}

type Elevation {
  unit: AngleUnits!
  value: Float!
}

type Frequency {
  units: String
  value: Float
}

enum FrequencyBand {
  KA_BAND
  NONE
  S_BAND
  X_BAND
}

type GsocAntennaDemodDecodeDetails {
  decodeConfigData: GsocDecodeConfigData!
  demodulationConfigData: GsocDemodulationConfigData!
  rfCharacteristics: GsocRfCharacteristics!
}

type GsocAntennaDownlinkDetails {
  rfCharacteristics: GsocRfCharacteristics!
}

type GsocAwsTemporaryCredentials {
  accessKeyId: String!
  expiration: AWSDateTime!
  secretAccessKey: String!
  sessionToken: String!
}

type GsocCapability {
  arn: String!
  id: String!
  images: [NamedImage!]!
  type: String!
}

type GsocConfig {
  arn: String!
  id: String!
  type: String!
}

type GsocConnectionProxyDetails {
  configArn: String
  dataflowTranslation: DataflowTranslation
}

type GsocContactDetails {
  acuImage: String
  agentReports: [AgentReport!]
  contactId: String!
  contactStatus: ContactStatus!
  contactVisualization: String
  customer: GsocCustomer!
  dataflows: [GsocDataflow!]
  datalakeTtl: AWSDateTime
  destinationRegions: [String!]!
  groundStations: [GsocGroundStation!]
  metadata: AWSJSON
  missionProfile: GsocMissionProfile!
  pk: String!
  satellite: GsocSatellite!
  schedulingRegion: String!
  tags: [String!]
  timeRange: TimeRange!
}

type GsocContactDynamoDbTable {
  contactId: String!
  contactStatus: ContactStatus!
  customer: GsocCustomer!
  dataflows: [GsocDataflow!]
  datalakeTtl: AWSDateTime
  destinationRegions: [String!]!
  groundStations: [GsocGroundStation!]
  missionProfile: GsocMissionProfile!
  pk: String!
  satellite: GsocSatellite!
  schedulingRegion: String!
  tags: [String!]
  timeRange: TimeRange!
}

type GsocContactEvent010 {
  contactId: String
}

type GsocContactIndexOpenSearch {
  contactId: String!
  contactStatus: ContactStatus!
  customer: GsocCustomer!
  dataflows: [GsocDataflow!]
  datalakeTtl: AWSDateTime
  destinationRegions: [String!]!
  groundStations: [GsocGroundStation!]
  missionProfile: GsocMissionProfile!
  pk: String!
  satellite: GsocSatellite!
  schedulingRegion: String!
  tags: [String!]
  timeRange: TimeRange!
}

input GsocContactStatusFilterInput {
  _eq: ContactStatus
  _exists: Boolean
  _in: [ContactStatus!]
  _neq: ContactStatus
  _nin: [ContactStatus!]
}

type GsocCustomer {
  awsAccountId: String
  awsGsaasAwsAccountNamePart: String!
  external: Boolean!
}

input GsocCustomerFilterInput {
  _and: [GsocCustomerFilterInput!]
  _exists: Boolean
  _not: GsocCustomerFilterInput
  _or: [GsocCustomerFilterInput!]
  awsAccountId: GsocStringFilterInput
  awsGsaasAwsAccountNamePart: GsocStringFilterInput
  external: BooleanFilterInput
}

input GsocCustomerSortInput {
  awsAccountId: SortOrder
  awsGsaasAwsAccountNamePart: SortOrder
  external: SortOrder
}

type GsocDataflow {
  dataflowId: String!
  destination: GsocNode!
  source: GsocNode!
  tags: [String!]!
}

enum GsocDataplaneType {
  DATA_DEFENDER
  LAMINAR_FLOW
}

type GsocDecodeConfigData {
  unvalidatedJson: String!
}

type GsocDemodulationConfigData {
  unvalidatedJson: String!
}

input GsocGetContactDetailsInput {
  date: AWSDateTime
  id: String!
}

type GsocGroundStation {
  antennaName: String
  groundStationId: String!
  groundStationName: String!
  region: String!
}

input GsocGroundStationFilterInput {
  _and: [GsocGroundStationFilterInput!]
  _exists: Boolean
  _not: GsocGroundStationFilterInput
  _or: [GsocGroundStationFilterInput!]
  antennaName: GsocStringFilterInput
  groundStationId: GsocStringFilterInput
  groundStationName: GsocStringFilterInput
  region: GsocStringFilterInput
}

type GsocIamRole {
  awsTemporaryCredentials: GsocAwsTemporaryCredentials
  isAuthorized: Boolean!
  name: String!
  roleArn: String!
}

type GsocIntegerRange {
  maximum: Int!
  minimum: Int!
}

input GsocListContactsPaginatedInput {
  cursor: [String!]
  limit: Int = 100
  sort: [ListGsocContactsSortInput!]
  where: ListGsocContactsFilterInput
}

type GsocListContactsPaginatedOutput {
  contacts: [GsocContactDetails!]!
  pageInfo: PageInfo!
}

type GsocListIamRolesOutput {
  roles: [GsocIamRole!]!
}

type GsocManagedAntennaDemodDecodeDetails {
  managedRadioConfigId: String!
  rfCharacteristics: GsocRfCharacteristics!
}

type GsocMissionProfile {
  missionProfileArn: String!
  missionProfileId: String!
  missionProfileName: String!
}

input GsocMissionProfileFilterInput {
  _and: [GsocMissionProfileFilterInput!]
  _exists: Boolean
  _not: GsocMissionProfileFilterInput
  _or: [GsocMissionProfileFilterInput!]
  missionProfileArn: GsocStringFilterInput
  missionProfileId: GsocStringFilterInput
  missionProfileName: GsocStringFilterInput
}

input GsocMissionProfileSortInput {
  missionProfileArn: SortOrder
  missionProfileId: SortOrder
  missionProfileName: SortOrder
}

type GsocNode {
  capability: GsocCapability!
  config: GsocConfig!
  nodeDetails: GsocNodeDetails!
  region: String!
}

type GsocNodeAgentDetails {
  version: String
}

type GsocNodeDataplaneDataDefenderDetails {
  dataDeliveryServiceDestinationArn: String
  dataDeliveryServiceSourceArn: String
  securityDetails: GsocSecurityDetails
  vita49StreamId: Int
}

type GsocNodeDataplaneDetails {
  dataDefender: GsocNodeDataplaneDataDefenderDetails
  dataflowType: GsocDataplaneType
  laminarFlow: GsocNodeDataplaneLaminarFlowDetails
}

type GsocNodeDataplaneLaminarFlowDetails {
  agent: GsocNodeAgentDetails
  egressAddress: GsocRangedConnectionDetails
  ingressAddress: GsocRangedConnectionDetails
  laminarflowConfig: String
  vita49StreamId: Int
}

type GsocNodeDetails {
  dataplane: GsocNodeDataplaneDetails
  orchestration: GsocNodeOrchestrationDetails
}

type GsocNodeOrchestrationDetails {
  antennaDemodDecodeDetails: GsocAntennaDemodDecodeDetails
  antennaDownlinkDetails: GsocAntennaDownlinkDetails
  connectionProxyDetails: GsocConnectionProxyDetails
  managedAntennaDemodDecodeDetails: GsocManagedAntennaDemodDecodeDetails
  s3PlaybackDetails: GsocS3PlaybackDetails
  s3RecordingDetails: GsocS3RecordingDetails
  uplinkDetails: GsocUplinkDetails
  uplinkEchoDetails: GsocUplinkEchoDetails
}

type GsocRangedConnectionDetails {
  name: String!
  portRange: GsocIntegerRange!
}

type GsocRfCharacteristics {
  bandwidth: Measurement
  centerFrequency: Measurement!
  polarization: Polarization!
}

type GsocS3PlaybackDetails {
  bucketArn: String!
  bucketRegion: String!
  keys: [String!]
  roleArn: String!
}

type GsocS3RecordingDetails {
  bucketArn: String!
  bucketRegion: String!
  keyTemplate: String
  roleArn: String!
}

type GsocSatellite {
  customer: GsocCustomer!
  satelliteArn: String!
  satelliteCatalogNumber: Int!
  satelliteName: String!
  tags: [String!]
}

input GsocSatelliteFilterInput {
  _and: [GsocSatelliteFilterInput!]
  _exists: Boolean
  _not: GsocSatelliteFilterInput
  _or: [GsocSatelliteFilterInput!]
  customer: GsocCustomerFilterInput
  satelliteArn: GsocStringFilterInput
  satelliteCatalogNumber: IntegerFilterInput
  satelliteName: GsocStringFilterInput
  tags: GsocStringFilterInput
}

input GsocSatelliteSortInput {
  satelliteArn: SortOrder
  satelliteCatalogNumber: SortOrder
  satelliteName: SortOrder
}

type GsocSecurityDetails {
  roleArn: String!
  securityGroupIds: [String!]!
  subnetIds: [String!]!
}

input GsocStringFilterInput {
  _eq: String
  _exists: Boolean
  _gt: String
  _gte: String
  _in: [String!]
  _lt: String
  _lte: String
  _neq: String
  _nin: [String!]
  _startsWith: String
}

type GsocUplinkDetails {
  rfCharacteristics: GsocRfCharacteristics!
  targetEirp: Measurement!
  transmitDisabled: Boolean!
}

type GsocUplinkEchoDetails {
  enabled: Boolean!
  rfCharacteristics: GsocRfCharacteristics!
}

type InRegionAntennaDownlinkConfigData {
  bandwidth: Frequency
  centerFrequency: Frequency
  polarity: Polarization
}

type InRegionAntennaDownlinkDemodDecodeConfigData {
  bandwidthValue: Frequency
  centerFrequency: Frequency
  decodeConfigData: InRegionDecodeConfigData
  demodulationConfigData: InRegionDemodulationConfigData
  polarity: Polarization
}

type InRegionAntennaDownlinkDemodDecodeExecutionContextData {
  antennaDownlinkDemodDecodeConfigData: InRegionAntennaDownlinkDemodDecodeConfigData
  antennaDownlinkDemodDecodeConfigId: String
  configArn: String
  dataflowType: CpDataflowType
  executionContextDataflows: [InRegionExecutionContextDataflowData!]
  outputToDataDeliveryServiceSourceArnMap: AWSJSON
  outputToDestinationMtuMap: AWSJSON
}

type InRegionAntennaDownlinkExecutionContextData {
  antennaDownlinkConfigData: InRegionAntennaDownlinkConfigData
  antennaDownlinkConfigId: String
  configArn: String
  dataDeliveryServiceSourceArn: String
  dataflowType: CpDataflowType
  executionContextDataflowData: InRegionExecutionContextDataflowData
}

type InRegionAntennaUplinkConfigData {
  centerFrequency: Frequency
  polarity: Polarization
  targetEirpUnit: String
  targetEirpValue: Float
  transmitDisabled: Boolean
}

type InRegionAntennaUplinkExecutionContextData {
  antennaUplinkConfigData: InRegionAntennaUplinkConfigData
  antennaUplinkConfigId: String
  configArn: String
  dataDeliveryServiceSourceArn: String
  dataflowType: CpDataflowType
  executionContextDataflowData: InRegionExecutionContextDataflowData
}

type InRegionConnectionProxyExecutionContextData {
  configArn: String
  dataflowTranslation: DataflowTranslation
  executionContextDataflowData: InRegionExecutionContextDataflowData
}

enum InRegionContactDataType {
  CONTACT_REQUEST
  EXECUTION_CONTEXT
}

type InRegionContactDdbTable {
  contactId: String!
  contactRequestData: InRegionContactRequestData
  dataUuid: String!
  executionContext: String
  executionContextStruct: InRegionExecutionContextData
  inRegionContactDataType: InRegionContactDataType!
  versionNumber: Int!
}

type InRegionContactRequestData {
  antennaSystemId: String
  awsAccountId: String
  capabilitiesToTest: [String!]
  contactArn: String
  contactId: String
  contactType: ContactType
  createdInRegion: String
  dereferenceMissionProfileData: DereferencedMissionProfileData
  groundstationId: String
  requestedNodes: AWSJSON
  satelliteArn: String
  targetRegion: String
}

type InRegionDataflowEndpointExecutionContextData {
  configArn: String
  dataDeliveryServiceDestinationArn: String
  dataDeliveryServiceSourceArn: String
  dataflowEndpointGroupArn: String
  dataflowType: CpDataflowType
  executionContextDataflowData: InRegionExecutionContextDataflowData
}

type InRegionDecodeConfigData {
  unvalidatedJSON: String
}

type InRegionDemodulationConfigData {
  unvalidatedJSON: String
}

type InRegionExecutionContextData {
  antennaDownlinkDemodDecodeExecutionContextData: InRegionAntennaDownlinkDemodDecodeExecutionContextData
  antennaDownlinkExecutionContextData: InRegionAntennaDownlinkExecutionContextData
  antennaUplinkExecutionContextData: InRegionAntennaUplinkExecutionContextData
  capabilityArn: String
  configArn: String
  connectionProxyExecutionContextData: InRegionConnectionProxyExecutionContextData
  dataflowEndpointExecutionContextData: InRegionDataflowEndpointExecutionContextData
  dataflowType: CpDataflowType
  executionContextId: String
  executionContextType: ConfigCapabilityType
  laminarFlowConfigString: String
  managedAntennaDownlinkDemodDecodeExecutionContextData: InRegionManagedAntennaDownlinkDemodDecodeExecutionContextData
  s3PlaybackExecutionContextData: InRegionS3PlaybackExecutionContextData
  s3RecordingExecutionContextData: InRegionS3RecordingExecutionContextData
  trackingExecutionContextData: InRegionTrackingExecutionContextData
  type: ConfigCapabilityType
  uplinkEchoExecutionContextData: InRegionUplinkEchoExecutionContextData
}

type InRegionExecutionContextDataflowData {
  dataflowId: String
  dataflowRate: Measurement
  destinationIngressAddress: InRegionRangedSocketAddressInfo
  egressAddress: InRegionRangedSocketAddressInfo
  ingressAddress: InRegionRangedSocketAddressInfo
  isMultiChannel: Boolean
  sourceAccount: String
  sourceArn: String
  sourceEgressAddress: InRegionRangedSocketAddressInfo
  vita49StreamId: Int
}

type InRegionManagedAntennaDownlinkDemodDecodeConfigData {
  managedRadioConfigId: String
  spectrumConfig: InRegionManagedAntennaDownlinkDemodDecodeSpectrumConfig
  supportedOutputTypes: [InRegionManagedAntennaDownlinkDemodDecodeSupportedOutputType!]
}

type InRegionManagedAntennaDownlinkDemodDecodeExecutionContextData {
  configArn: String
  dataflowType: CpDataflowType
  executionContextDataflows: [InRegionExecutionContextDataflowData!]
  managedAntennaDownlinkDemodDecodeConfigData: InRegionManagedAntennaDownlinkDemodDecodeConfigData
  managedAntennaDownlinkDemodDecodeConfigId: String
}

type InRegionManagedAntennaDownlinkDemodDecodeSpectrumConfig {
  bandwidth: Frequency
  centerFrequency: Frequency
  polarization: Polarization
}

type InRegionManagedAntennaDownlinkDemodDecodeSupportedOutputType {
  description: String
  name: String
}

type InRegionRangedSocketAddressInfo {
  maximumPort: Int
  minimumPort: Int
  name: String
}

type InRegionS3PlaybackConfigData {
  bucketArn: String
  bucketRegion: String
  playbackFrequency: Frequency
  roleArn: String
}

type InRegionS3PlaybackConfigDto {
  awsAccountId: String
  configArn: String
  configId: String
  s3PlaybackConfigData: InRegionS3PlaybackConfigData
  type: ConfigCapabilityType
}

type InRegionS3PlaybackExecutionContextData {
  configArn: String
  dataflowType: CpDataflowType
  executionContextDataflowData: InRegionExecutionContextDataflowData
  s3PlaybackConfigDto: InRegionS3PlaybackConfigDto
}

type InRegionS3RecordingConfigData {
  bucketArn: String
  bucketRegion: String
  prefix: String
  roleArn: String
}

type InRegionS3RecordingConfigDto {
  awsAccountId: String
  configArn: String
  configId: String
  createdInRegion: String
  name: String
  region: String
  s3RecordingConfigData: InRegionS3RecordingConfigData
  type: ConfigCapabilityType
}

type InRegionS3RecordingExecutionContextData {
  dataDeliveryServiceDestinationArn: String
  dataDeliveryServiceSourceArn: String
  dataflowType: CpDataflowType
  executionContextDataflowData: InRegionExecutionContextDataflowData
  s3RecordingConfigDto: InRegionS3RecordingConfigDto
}

type InRegionTrackingExecutionContextData {
  autotrackCriticality: CpCriticality
}

type InRegionUplinkEchoConfigData {
  antennaUplinkArn: String
  centerFrequency: Frequency
  enabled: Boolean
  polarity: Polarization
}

type InRegionUplinkEchoExecutionContextData {
  configArn: String
  dataDeliveryServiceSourceArn: String
  dataflowType: CpDataflowType
  executionContextDataflowData: InRegionExecutionContextDataflowData
  uplinkEchoConfigData: InRegionUplinkEchoConfigData
  uplinkEchoConfigId: String
}

input IntegerFilterInput {
  _eq: Int
  _exists: Boolean
  _gt: Int
  _gte: Int
  _in: [Int!]
  _lt: Int
  _lte: Int
  _neq: Int
  _nin: [Int!]
}

input ListGsocContactsFilterInput {
  _and: [ListGsocContactsFilterInput!]
  _not: ListGsocContactsFilterInput
  _or: [ListGsocContactsFilterInput!]
  contactId: GsocStringFilterInput
  contactStatus: GsocContactStatusFilterInput
  customer: GsocCustomerFilterInput
  destinationRegions: GsocStringFilterInput
  groundStations: GsocGroundStationFilterInput
  missionProfile: GsocMissionProfileFilterInput
  satellite: GsocSatelliteFilterInput
  schedulingRegion: GsocStringFilterInput
  tags: GsocStringFilterInput
  timeRange: TimeRangeFilterInput
}

input ListGsocContactsSortInput {
  contactId: SortOrder
  contactStatus: SortOrder
  customer: GsocCustomerSortInput
  missionProfile: GsocMissionProfileSortInput
  satellite: GsocSatelliteSortInput
  schedulingRegion: SortOrder
  timeRange: TimeRangeSortInput
}

scalar Long

type Measurement {
  unit: String!
  value: Float!
}

enum MeteringStatus {
  METERING_FAILED
  METERING_QUEUED
  METERING_REQUIRED_CANCELLED
  METERING_REQUIRED_COMPLETED
  METERING_REQUIRED_NEGATIVE
}

enum MeteringUsage {
  MIDBAND
  NARROWBAND
  WIDEBAND
}

type NamedImage {
  imageBase64: String!
  name: String!
}

type PageInfo {
  hasNextPage: Boolean!
  lastCursor: [String!]
}

enum Polarization {
  BOTH
  LEFT_HAND
  NONE
  RIGHT_HAND
}

type PostPassImage {
  dataflowId: String
  imageBase64: String!
  jobId: String
  s3Key: String!
}

type Query {
  echo(cazJustification: String, input: EchoInput!): EchoOutput
  getContactById(cazJustification: String, input: ContactByIdInput!): Contact
  getUserPermissionClaims: [String!]!
  gsocGetContactDetails(cazJustification: String, input: GsocGetContactDetailsInput!): GsocContactDetails
  gsocListContactsPaginated(cazJustification: String, input: GsocListContactsPaginatedInput!): GsocListContactsPaginatedOutput
  gsocListIamRoles(cazJustification: String): GsocListIamRolesOutput
}

type RequestedDagNodeInfo {
  dataflowUuid: String
  region: String
  versionedConfigId: String
  vita49StreamId: String
}

enum ReservationStatus {
  AWS_FAILED
  CANCELLED
  COMPLETED
  DELETED
  FAILED
  PASS
  POSTPASS
  PREPASS
  SCHEDULED
  SCHEDULING
}

type RfCharacteristics {
  frequencyBand: FrequencyBand
  polarization: Polarization
}

enum SortOrder {
  asc
  desc
}

type TaskReport {
  postpassReport: String
  taskId: String!
}

type TimeRange {
  endTime: AWSDateTime!
  startTime: AWSDateTime!
}

input TimeRangeFilterInput {
  _exists: Boolean
  endTime: AWSDateTimeFilterInput
  startTime: AWSDateTimeFilterInput
}

input TimeRangeSortInput {
  endTime: SortOrder
  startTime: SortOrder
}

type TranslationConfiguration {
  laminarFlowConfig: String
  type: DeliveryMechanism
  vpcAttachmentDetails: VpcAttachmentDetails
}

type TranslationDetail {
  deliveryMechanism: DeliveryMechanism
  translationConfiguration: TranslationConfiguration
}

type Vita49StreamCharacteristics {
  vitaInterfaceType: Vita492SchemaType
  vitaStreamId: Int
}

enum Vita492SchemaType {
  AWS_GS_TYPE_1
  AWS_GS_TYPE_2
}

type VpcAttachmentDetails {
  customerRoleArn: String
  dataflowEndpointGroupArn: String
  securityGroupIdList: [String!]
  subnetIdList: [String!]
}
`;
