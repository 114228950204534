import { AwsRum } from 'aws-rum-web';
import * as React from 'react';
import { RumMidwayCredentialsProvider } from '@amzn/rum-midway-credentials-provider';
import { InstrumentationContext } from './Instrumentation';
import { useAppSettings } from './AppSettings';
import { useUsername } from './Midway';

const APPLICATION_VERSION = '1.0.0';
const USER_SCRIPTS_TO_IGNORE = ["Garrett's-Loki-Contact-Identifier.user.js", 'AWS-Region-Mapper.user.js'];

/**
 * Instrumentation context.
 *
 * Used to provide instrumentation to the application.
 *
 * @param props.children Nested components
 * @returns
 */
export function InstrumentationProvider(props: { readonly children: React.ReactNode }) {
  const username = useUsername();
  const appSettings = useAppSettings();

  const providedValue = React.useMemo(() => {
    if (appSettings.rumApplicationId !== undefined && appSettings.rumRoleArn !== undefined) {
      const rumClient = new AwsRum(appSettings.rumApplicationId, APPLICATION_VERSION, appSettings.region, {
        sessionSampleRate: 1,
        endpoint: `https://dataplane.rum.${appSettings.region}.amazonaws.com`,
        telemetries: [
          [
            'errors',
            {
              // see https://cloudscape.aws.dev/versions/from-v21-to-v30/known-migration-issues/#client-side-errors-due-to-resizeobserver
              ignore: (event: ErrorEvent) =>
                event.message === 'ResizeObserver loop completed with undelivered notifications.' ||
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                USER_SCRIPTS_TO_IGNORE.some(fileToIgnore => event?.filename?.includes(fileToIgnore)),
            },
          ],
          'http',
          'performance',
        ],
        allowCookies: true,
        enableXRay: true,
        sessionAttributes: {
          // WARNING: This field is being used to track internal ops console usage
          // and identify users when investigating errors or just know overall usage in GSOC
          // This should not be moved to anything involving external customers without removing
          // this additional session attribute.
          user: username,
        },
      });

      rumClient.setAwsCredentials(
        new RumMidwayCredentialsProvider(appSettings.region, window.location.origin, appSettings.rumRoleArn)
          .ChainAnonymousCredentialsProvider,
      );

      return { rumClient };
    }
    return { rumClient: null };
  }, [appSettings.region, appSettings.rumApplicationId, appSettings.rumRoleArn, username]);

  return <InstrumentationContext.Provider value={providedValue}>{props.children}</InstrumentationContext.Provider>;
}
