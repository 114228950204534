import { DataProductApi } from '../constants/api';
import { useAppSettings } from '../context/AppSettings';

// Setting this hard coded for now.  We don't want to have to import any rip dependencies.
const regionToAirportMappings: Record<string, string> = {
  'us-east-1': 'iad',
  'us-west-1': 'sfo',
  'us-west-2': 'pdx',
  'us-east-2': 'cmh',
};

const constructApiEndpoint =
  (region: string, stage: string) =>
  (prefix: string, apiName: string): string => {
    const airport = regionToAirportMappings[region];
    return `https://${prefix}.${airport}.${stage}.${apiName}.groundstation.aws.dev/graphql`;
  };

export const useDataProductEndpoints = (): Record<DataProductApi, string> => {
  const { graphqlApiUrl, region, stage } = useAppSettings();
  const endpointCreator = constructApiEndpoint(region, stage);
  const record: Record<DataProductApi, string> = {
    [DataProductApi.DATA_PLANE_API]: endpointCreator('api', 'agent-dp'),
    [DataProductApi.CURATED_API]: graphqlApiUrl, // Can replace this later with generated api endpoint if desired.
  };
  return record;
};
